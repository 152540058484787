<template>
    <div>
        <CCardHeader class="mb-3">
            <strong>検品</strong>
        </CCardHeader>
        <ModalComponent
            title="Scan QR Code"
            color="warning"
            @update:show="handleEventModal"
            :show.sync="openScanQR"
        >
            <CRow v-if="openScanQR" class="text-center">
                <div class="w-100">
                    <div>
                        <qrcode-stream @decode="onDecode" @init="onInit" style="text-align: center">
                            <div style="padding-top: 80px">
                              <div v-if="result" style="color: #22ff34;font-size: 40px;">{{ result }}</div>
                              <div v-if="resultError" class="text_result">{{ resultError }}</div>
                            </div>
                        </qrcode-stream>

                    </div>
                </div>
            </CRow>
        </ModalComponent>

        <CButton style="height: 80px" color="primary" class="mb-3" size="lg" @click="() => handleOpenScanQR(true)">
            QRコードをスキャンして登録する
        </CButton>
        <div>
<!--            {{itemMasters}}-->
            <CDataTable
                hover
                striped
                border
                :itemsPerPage="200"
                :items="itemMasters"
                :fields="fields"
            >
                <td slot="status" slot-scope="{item, index}">
                    <div>
                        <CSelect
                            label=""
                            horizontal
                            v-model="item.status"
                            :options="options"
                            @change="(e) => selectChange(e, index)"
                        />
                    </div>
                </td>
                <td slot="delete" slot-scope="{item, index}">
                    <div>
                        <CButtonClose @click="() => handleDelete(item)"/>
                    </div>
                </td>
            </CDataTable>
        </div>
        <vue-confirm-dialog></vue-confirm-dialog>

        <CRow class="text-center">
            <CCol md="6">
                <CButton  style="height: 80px" color="primary" @click="() => handleConfirm()">
                    上記内容で登録する
                </CButton>
            </CCol>
            <CCol md="6">
                <CButton style="height: 80px" color="primary" @click="() => $router.back()">
                    キャンセル
                </CButton>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'
import apiStock from '../../api/stockApi'
import {SCREEN_KEY} from "@/utils/constance";
import apiStockOther from "@/api/stockApi";
import Vue from "vue";
import ModalComponent from "@/components/views/ModalComponent";
import {numberWithCommas} from "@/utils/utils";

export default {

    components: {ModalComponent, QrcodeStream },

    data () {
        return {
            result: '',
            resultError: '',
            currentItemMaster: '',
            error: '',
            lastItemId: '',
            typeSelected: 1,
            itemMasters: [],
            itemIds: [],
            openScanQR: false,
            options: [{label: '入庫', value: 1}, {label: '返品', value: 2}],
            fields:  [
                { key: 'item_id', label: '商品ID' },
                { key: 'item_code', label: '商品コード' },
                { key: 'name', label: '品名' },
                { key: 'size', label: 'サイズ' },
                { key: 'color', label: '色' },
                { key: 'pp_bs_code', label: 'PP/BB' },
                { key: 'cost', label: '仕入金額' },
                { key: 'price', label: '販売金額' },
                { key: 'retail_price', label: 'プロパー上代' },
                { key: 'status', label: '入庫/返品' },
                { key: 'delete', label: '削除' },
            ]
        }
    },

    created() {
    },

    methods: {
        handleConfirm(){
            this.$confirm(
                {
                    title: '確認',
                    message: '実行してもよろしいですか？',
                    button: {
                        no: 'いいえ',
                        yes: 'はい'
                    },
                    callback: confirm => {
                        if (confirm) {
                            // ... do something
                            this.handleChangeStatus()
                        }
                    }
                }
            )
        },
        handleChangeStatus(){
            this.loading = true
            if(!this.itemMasters.length){
                Vue.$toast.error('少なくとも1つの製品が必要です')
                return;
            }
            let params = {
                stocks:  this.itemMasters,
            }
            apiStockOther.changeStatusStockByScanQR(params).then(response => {
                Vue.$toast.success('検品が完了しました。');
                this.$router.back();
            }).catch(error => {
                Vue.$toast.error('Error')
            }).finally(() =>{
                this.loading = false
            })
        },
        onDecode (result) {
          this.lastItemId = result
            if(!this.itemIds.includes(result)){
                this.result = ''
                this.getItem(result)
                this.itemIds.push(result)
            }
        },
        selectChange (val, key) {
            this.itemMasters[key].status = parseInt(val.target.value)
        },
      handleEventModal(e, args, accept){
        if(accept){
          this.handleAddItem();
        }else {
          if(this.lastItemId){
            let ids = this.itemIds;
            this.itemIds = ids.filter(i => i != this.lastItemId)
          }
          this.result = '';
          this.resultError = '';
          this.currentItemMaster = '';
        }
      },
      handleOpenScanQR (value) {
          this.openScanQR = value;
      },
      handleDelete (item){
          this.itemMasters = this.itemMasters.filter(i => i.id !== item.id)
          // this.itemMasters.splice(index)
      },

      handleAddItem(){
          let data = this.currentItemMaster;
          let item = {
            id: data.id,
            item_id: data.item_id,
            item_code: data.item.item_master.item_code,
            name: data.item.item_master.name,
            size: data.item.item_master.size,
            color: data.item.item_master.color,
            pp_bs_code: data.item.item_master.pp_bs_code,
            cost: numberWithCommas(data.item.item_master.cost),
            price: numberWithCommas(data.item.item_master.price),
            retail_price: numberWithCommas(data.item.item_master.retail_price),
            status: 1,
          }

        if(!this.containsObject(item)){
          this.itemMasters.push(item)
          this.result = ''
          this.resultError = ''
        }else {
          this.result = '';
          this.resultError = '製品が追加されました。';
        }
        this.currentItemMaster = '';
      },

        getItem (item_id) {
            // this.resetSelected()
            apiStock.itemScanQR({item_id, screen_key: SCREEN_KEY.SCAN_QR_CODE}).then(response => {
                let data = response.data;
                if(!data){
                    this.result = '';
                    this.resultError = '在庫にその商品が無いか、検品できる状態ではありません。';
                    return
                }
                this.currentItemMaster = data;
                this.result = 'スキャンが完了しました。 \n 商品ID：'+ data.item.item_master.item_code + '\n 商品コード：' + data.item_id
                this.resultError = ''
                // this.loading = false
            }).catch(error => {
                this.resultError = '商品が在庫にありません。'
                // this.loading = false
            })
        },
         containsObject(obj) {
            let x = false;

            this.itemMasters.map(i => {
                if(i.id === obj.id){
                    x = true;
                }
            })

            return x;
        },
        async onInit (promise) {
            try {
                await promise
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    this.error = "ERROR: you need to grant camera access permisson"
                } else if (error.name === 'NotFoundError') {
                    this.error = "ERROR: no camera on this device"
                } else if (error.name === 'NotSupportedError') {
                    this.error = "ERROR: secure context required (HTTPS, localhost)"
                } else if (error.name === 'NotReadableError') {
                    this.error = "ERROR: is the camera already in use?"
                } else if (error.name === 'OverconstrainedError') {
                    this.error = "ERROR: installed cameras are not suitable"
                } else if (error.name === 'StreamApiNotSupportedError') {
                    this.error = "ERROR: Stream API is not supported in this browser"
                }
            }
        }
    }
}
</script>

<style scoped>
.error {
    font-weight: bold;
    color: red;
}
.text_result{
  font-size: 40px;
  color: red;
}
</style>
